@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&family=Raleway:wght@200&family=Varela+Round&display=swap');
body {
  font-family: comfortaa;
  font-weight: 600;
  color: white;
  margin: .5rem;
}

.name {
  border-left: 2.5rem solid;
  /* border-radius: 10px; */
  padding: 4rem;
  background-color: #ffffff32;
  animation: popout .4s ease;
  -webkit-animation: popout .8s ease;
  transition: all 0.3s ease 0s;
  /* cursor: context-menu; */
  font-size: 1.5rem ;
  height: 39rem;
  display: flex;
  gap: 15rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
  display: flex;
  flex-direction: row-reverse;
}

.copylink {
  text-align: center;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.copylink:hover {
  transform: translateY(-7px);
  background: none;
  color: #000000;

}

.copylink:active {
  transform: translateY(-1px);
}

.links > a > p {
  background: #fffefe;
  padding: .3rem;
  text-align: center;
  width: 8.9rem;
  transition: all 0.3s ease 0s;
  /* cursor: pointer; */
  border-radius: 10px;
}

.links > a > p:hover {
  transform: translateY(-7px);
  background: none;
  color: #fffefe;
}


.links2 > a > p {
  background: #fffefe;
  padding: .3rem;
  text-align: center;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border-radius: 10px;
}

.links2 > a > p:hover {
  transform: translateY(-7px);
  background: none;
  color: #fffefe;
}

.contact {
  display:flex;
  justify-content: space-around;
  background: #fffefe61;
  align-items: center;
  font-size: 2rem;
}
.links2 {
  display: flex;
  flex-direction: row;
  gap: 3em;
}

.realName {
  font-size: 5vw;
  padding: 5vw;
  text-align: left;
  background: linear-gradient(132deg, #0fff07, #3e9bff, #13e3ff);
  background-size: 400% 400%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  line-height: 1.6;
  -webkit-animation:text-shadow-pop-right .6s both, shadow-pop-br 0.3s cubic-bezier(0.470, 0.000, 0.745, 0.715) both; animation:text-shadow-pop-right 2.5s both, Gradient 15s ease infinite, shadow-pop-br 0.5s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;  
}


@-webkit-keyframes shadow-pop-br {
  0% {
    -webkit-box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
            box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
  100% {
    -webkit-box-shadow: 1px 1px #3e3e3e, 2px 2px #3e3e3e, 3px 3px #3e3e3e, 4px 4px #3e3e3e, 5px 5px #3e3e3e, 6px 6px #3e3e3e, 7px 7px #3e3e3e, 8px 8px #3e3e3e;
            box-shadow: 1px 1px #3e3e3e, 2px 2px #3e3e3e, 3px 3px #3e3e3e, 4px 4px #3e3e3e, 5px 5px #3e3e3e, 6px 6px #3e3e3e, 7px 7px #3e3e3e, 8px 8px #3e3e3e;
    -webkit-transform: translateX(-8px) translateY(-8px);
            transform: translateX(-8px) translateY(-8px);
  }
}
@keyframes shadow-pop-br {
  0% {
    -webkit-box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
            box-shadow: 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e, 0 0 #3e3e3e;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
  100% {
    -webkit-box-shadow: 1px 1px #3e3e3e, 2px 2px #3e3e3e, 3px 3px #3e3e3e, 4px 4px #3e3e3e, 5px 5px #3e3e3e, 6px 6px #3e3e3e, 7px 7px #3e3e3e, 8px 8px #3e3e3e;
            box-shadow: 1px 1px #3e3e3e, 2px 2px #3e3e3e, 3px 3px #3e3e3e, 4px 4px #3e3e3e, 5px 5px #3e3e3e, 6px 6px #3e3e3e, 7px 7px #3e3e3e, 8px 8px #3e3e3e;
    -webkit-transform: translateX(-8px) translateY(-8px);
            transform: translateX(-8px) translateY(-8px);
  }
}

 @-webkit-keyframes text-shadow-pop-right{0%{text-shadow:0 0 #555,0 0 #555,0 0 #555,0 0 #555,0 0 #555,0 0 #555,0 0 #555,0 0 #555;-webkit-transform:translateX(0);transform:translateX(0)}100%{text-shadow:1px 0 #555,2px 0 #555,3px 0 #555,4px 0 #555,5px 0 #555,6px 0 #555,7px 0 #555,8px 0 #555;-webkit-transform:translateX(-8px);transform:translateX(-8px)}}@keyframes text-shadow-pop-right{0%{text-shadow:0 0 #555,0 0 #555,0 0 #555,0 0 #555,0 0 #555,0 0 #555,0 0 #555,0 0 #555;-webkit-transform:translateX(0);transform:translateX(0)}100%{text-shadow:1px 0 #555,2px 0 #555,3px 0 #555,4px 0 #555,5px 0 #555,6px 0 #555,7px 0 #555,8px 0 #555;-webkit-transform:translateX(-8px);transform:translateX(-8px)}}


.name h2 {
  background: #fffefe;
  color: #000000;
  padding: 1rem;
  width: 22.5rem;
  animation: fadeInAnimation ease 2.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forward;
  margin-bottom: 5rem;
  -webkit-animation:flicker-in-1 2s linear both;animation:flicker-in-1 2s linear both
}

@-webkit-keyframes flicker-in-1{0%{opacity:0}10%{opacity:0}10.1%{opacity:1}10.2%{opacity:0}20%{opacity:0}20.1%{opacity:1}20.6%{opacity:0}30%{opacity:0}30.1%{opacity:1}30.5%{opacity:1}30.6%{opacity:0}45%{opacity:0}45.1%{opacity:1}50%{opacity:1}55%{opacity:1}55.1%{opacity:0}57%{opacity:0}57.1%{opacity:1}60%{opacity:1}60.1%{opacity:0}65%{opacity:0}65.1%{opacity:1}75%{opacity:1}75.1%{opacity:0}77%{opacity:0}77.1%{opacity:1}85%{opacity:1}85.1%{opacity:0}86%{opacity:0}86.1%{opacity:1}100%{opacity:1}}@keyframes flicker-in-1{0%{opacity:0}10%{opacity:0}10.1%{opacity:1}10.2%{opacity:0}20%{opacity:0}20.1%{opacity:1}20.6%{opacity:0}30%{opacity:0}30.1%{opacity:1}30.5%{opacity:1}30.6%{opacity:0}45%{opacity:0}45.1%{opacity:1}50%{opacity:1}55%{opacity:1}55.1%{opacity:0}57%{opacity:0}57.1%{opacity:1}60%{opacity:1}60.1%{opacity:0}65%{opacity:0}65.1%{opacity:1}75%{opacity:1}75.1%{opacity:0}77%{opacity:0}77.1%{opacity:1}85%{opacity:1}85.1%{opacity:0}86%{opacity:0}86.1%{opacity:1}100%{opacity:1}}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

button {
  border: none;
  padding: 1rem;
}

.am {
  background: #fffefe;
  color: #000000;
  padding: 4rem;
  border-radius: 10px;
  animation: popout .4s ease;
  -webkit-animation: popout 1.5s ease;
  transition: all 0.3s ease 0s;
  /* cursor: none; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  font-size: 1.5rem;
}

.am p {
  line-height: 1.5;
}


.pj {
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 3rem;
  /* width: 200000px; */
  background-color: rgba(225, 237, 231, 0.627);
  border-radius: 10px;
  padding: 1rem 3rem 3rem;
  color: black;
  animation: popout .4s ease;
  -webkit-animation: popout 1.9s ease;
  transition: all 0.3s ease 0s;
  /* cursor: pointer; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  
}
@keyframes popout {
  from{transform:scale(1)}
  10%{transform:scale(1.05)}
  to{transform:scale(1)}
}
@-webkit-keyframes popout {
  from{-webkit-transform:scale(1)}
  10%{-webkit-transform:scale(1.1)}
  to{-webkit-transform:scale(1)}
}
.pj:hover {
  box-shadow: 7px 7px #fffefe;
  transform: translateY(-7px);
}

.pj:active {
  transform: translateY(-1px);
}

.pj h1 {
  background: rgb(241, 245, 246);
  border-radius: 10px;
  padding: 1rem;
  width: 20rem;
  text-align: center;
  transition: all 0.3s ease 0s;
  /* cursor: pointer; */
  font-size: 3rem;
  
}

.pj h1:hover {
  transform: translateY(-7px);
  background: none;
  color: #fffefe;
}

.pj h1:active {
  transform: translateY(-1px);
}

.content {
  padding: 4rem;
  background: linear-gradient(132deg, #0fff07, #3e9bff, #13e3ff);
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column; 
  border-radius: 10px;
}
.content > div {
  margin: 3rem;
  transition: all 0.3s ease 0s;
}

.pj > div {
  border-radius: 10px;
  padding: 2rem;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  /* text-align: center; */
  display: flex;
  align-items: center;
  font-size: 25px;
  gap: 3rem;
  line-height: 1.6;

}

.pj > div > a:first-child > p {
  text-decoration: underline;
  text-underline-offset: 8px;
}

.pj > div:hover {
  /* box-shadow: 5px 10px #888888; */
  transform: translateY(-10px);
  background: rgb(241, 245, 246);
  border-radius: 10px;

}


@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  40% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

a {
  color: #000000;
  text-decoration: none;
}

.me {
  display: flex;
  justify-content: space-between;
}
/* ***************************************************************************************** */
/* ***************************************************************************************** */
/* Mobile CSS */

@media screen and (max-width: 1700px) {

  .name {
    flex-direction: column-reverse;
    gap: 1rem;
  }


}

@media screen and (max-width: 1475px) {

  .pj > div {
    flex-direction: column;
  }
}


@media screen and (max-width: 1280px) {
  
  .realName {
    font-size: 4.5vw;
    padding: 4rem;
  }

  
}

@media screen and (max-width: 1255px) {
  img {
    max-width: 100%;
    height: auto
  }
  .realName {
    font-size: 7vw;
    padding: 5rem;
  }

  .name {
    height: 56rem;
  }

}
@media screen and (max-width: 820px) {

  .content {
    padding: 0rem;
  }

  .name {
    font-size: 2vw;
  }

  .name h2 {
    width: 30vw;
  }

  .realName {
    font-size: 7vw;
    padding: 6rem;
  }

  .contact {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 680px) {
  .realName {
    font-size: 6vw;
    padding: 2rem;
    text-align: center;
  }

  .infoAndSlogan {
    font-size: 2.7vw;
  }

  .name {
    height: 55em;
  }

}

@media screen and (max-width: 560px) {

  body {
    margin: .2rem;
  }
  
  .content > div {
    margin: 1.5rem;
  }

  .realName {
    font-size: 9vw;
    padding: 2.5rem 1rem 2.5rem;
  }

  img {
    max-width: 100%;
    height: auto
  }
  .name {
    border-left: .5rem solid;
    padding: 1.5rem;
    height: 70em;
  }

  .am {
    font-size: 4vw;
    padding: 1.5rem;
  }

  .pj h1 {
    font-size: 7vw;
    width: 85%;
    display: flex;
    justify-content: center;
  }
  .pj div {
    font-size: 5vw;
    padding: 1rem;
  }
  .pj {
    padding: 1rem;
  }
}

